<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-step-one-dialog"
    style="padding: 10px"
  >
    <div slot="title" class="dialog-header">
      <div class="dialog-index">1</div>
      <span class="dialog-title"> {{ $t("assocaite.DiologOne.title") }} </span>
    </div>
    <div v-if="dialogVisible">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="200px"
        label-position="right"
        size="small"
      >
        <el-form-item
          :label="$t('assocaite.DiologOne.Relationship')"
          prop="bindType"
        >
          <el-radio v-model="form.bindType" label="0">{{
            $t("assocaite.DiologOne.Relationship1")
          }}</el-radio>
          <el-radio v-model="form.bindType" label="1">{{
            $t("assocaite.DiologOne.Relationship2")
          }}</el-radio>
        </el-form-item>
        <el-form-item
          :label="$t('assocaite.DiologOne.scene')"
          prop="scenarioId"
        >
          <el-select
            v-model="form.scenarioId"
            clearable
            :placeholder="$t('assocaite.DiologOne.Select a Scenario')"
            ref="scenarioRef"
          >
            <el-option
              v-for="item in scenarioList"
              :key="item.scenarioId"
              :label="item.translateTextName"
              :value="item.scenarioId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('assocaite.DiologOne.Filter bound price tags')"
        >
          <el-switch v-model="form.isFilter" />
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        style="width: 100%"
        :data="tableList"
        row-key="goodsTagMiddleId"
        @selection-change="handleSelectionChange"
        show-empty
        :empty-text="$t('assocaite.NoData')"
        class="pleft10"
      >
        <el-table-column width="100" type="selection"> </el-table-column>
        <el-table-column
          fixed="right"
          :label="$t('assocaite.DiologOne.table.All')"
          prop="typeName"
        >
        </el-table-column>

        <el-table-column
          fixed="right"
          align="center"
          :label="$t('assocaite.DiologOne.table.chooseLayout')"
        >
          <!-- @change="handleChangeType" -->
          <!-- / -->
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.layoutStrategyId"
              @focus.stop="getLayoutList(scope.row)"
              :placeholder="$t('assocaite.DiologOne.Please Select A Layout')"
              size="small"
              @change="layoutChange(scope.row)"
              clearable
              :disabled="
                !form.scenarioId || !selectedDataIds.includes(scope.row.id)
              "
            >
              <el-option
                v-for="item in layoutList"
                :key="item.layoutStrategyId"
                :label="item.layoutStrategyName"
                :value="item.layoutStrategyId"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>

      <div style="height: 50px"></div>

      <div slot="footer" class="dialog-footer__1">
        <el-button
          class="task-manger-button-next green-button"
          @click="submitForm"
          size="small"
        >
          {{ $t("taskManagerInfo.Next") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  getTagList,
  getScenarioList,
  getLayoutData,
} from "@/libs/api/associate";

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      scenarioList: [],
      form: {
        bindType: "0",
        scenarioId: "",
        isFilter: true,
      },
      rules: {
        bindType: {
          required: true,
          trigger: "change",
        },
        scenarioId: {
          required: true,
          trigger: "change",
          message: this.$t('assocaite.DiologOne.Select a Scenario'),
        },
      },
      tableList: [],
      layoutList: [],
      selectedData: [],
      selectedDataIds: [],
    };
  },
  computed: {},
  watch: {
    dialogVisible(val) {
      if (val) {
        // this.getData();
        // this.getScenarioDate();
        setTimeout(() => {
          if (this.selectedData.length > 0) {
            this.selectedData.forEach((row) => {
              this.getLayoutList(row)
              this.$refs.multipleTable.toggleRowSelection(row, true);
            });
          }
        }, 300);
      }
    },
    selectedData(val) {
      console.log(val)
      if(val.length >0){
        this.selectedDataIds = val.map( item => {
          return item.id
        })
      }else{
        this.selectedDataIds = []
      }

      console.log('+++++',this.selectedDataIds)
    }
  },
  methods: {
    // 选择布局时绑定布局id
    layoutChange(row){
      console.log(row)
      let currentLayout = this.layoutList.filter(item => item.layoutStrategyId == row.layoutStrategyId)
      // console.log(currentLayout[0].children[0].layoutId)
      row.layoutId = currentLayout.length > 0 ? currentLayout[0].children[0].layoutId : ''
    },
    // 获取场景列表
    getScenarioDate() {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let params = {
        merchantId: userData.merchantId,
        textKey: "",
        scenarioKey: "",
        isEffect: 0,
        agentId: "",
      };
      getScenarioList(params).then((res) => {
        this.scenarioList = res.data.data;
      });
    },
    // 获取价签尺寸列表
    getData() {
      let params = {
        storeId: localStorage.getItem("storeId"),
      };
      getTagList(params).then((res) => {
        this.tableList = res.data.data;
      });
    },
    // 获取布局
    getLayoutList(row) {
      let params = {
        storeId: localStorage.getItem("storeId"),
        storeQueryType: "0",
        strategyType: "0",
        groupSize: row.tagTypeId,
        scenarioId: this.form.scenarioId, //场景id
        current: "1",
        size: "9999999",
      };
      getLayoutData(params).then((res) => {
        if (res.data.data.length > 0) {
          this.layoutList = res.data.data[0].children;
        } else {
          this.layoutList = [];
        }
      });
    },
    openDialog(row) {
      if (row) {
        this.form = row;
        this.selectedData = row.selectedData;
        // this.selectedData.forEach((row) => {
        //   this.$refs.multipleTable.toggleRowSelection(row, true)
        // })
        // this.selectedData.forEach((row) => {
        //       this.$refs.multipleTable.toggleRowSelection(row, true);
        //     });
      } else {
        this.getData();
        this.getScenarioDate();
        this.scenarioList = [];
        this.form = {
          bindType: "0",
          scenarioId: "",
          isFilter: true,
        };
        this.tableList = [];
        this.layoutList = [];
        this.selectedData = [];
      }
      this.dialogVisible = true;
    },
    cancel() {
      this.dialogVisible = false;
    },
    handleSelectionChange(selection) {
      this.selectedData = selection;
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.selectedData.length < 1) {
            this.$message.error(this.$t('assocaite.DiologOne.ESLType is Null'));
            return;
          }
          const isFinished = this.selectedData.some(
            (item) => !item.layoutStrategyId || item.layoutStrategyId == ""
          );
          if (isFinished) {
            this.$message.error(this.$t('assocaite.DiologOne.Please Select A Layout'));
          } else {
            const params = {
              ...this.form,
              selectedData: this.selectedData,
            };
            console.log(params);
            this.$emit("stepTwo", params);
            this.dialogVisible = false;
          }
        }
      });
    },
  },
};
</script>

<style>
.pleft10 .el-table-column--selection .cell {
  padding-left: 10px !important;
}
</style>

<style lang="scss" scoped>
.task-manger-step-one-dialog {
  .el-radio-group {
    vertical-align: text-top;
  }
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #e9f9ef;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #d3f4e1;
  }

  .el-dialog__body {
    padding: 30px 100px !important;
  }

  // .el-radio-button__inner, .el-radio-group {
  //   display: inline-block;
  //   vertical-align: middle;
  //   font-size: 0;
  // }

  //  .el-radio-group {
  //   display: inline-block;
  //   vertical-align: middle;
  //   font-size: 0;
  // }

  .dialog-footer__1 {
    display: flex;
    justify-content: center;
    margin-top: 50;
  }

  .task-manger-button-next {
    // border: 0px;
    width: 110px;
  }
}
</style>
