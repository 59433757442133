import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

// params =>form;  data => json
function getByEslCode(data) {
  // 获取价签详情信息
  return router({
    url: baseUrl+"tag/v1/getByEslCode",
    data:data,
    method: "POST",
  });
}

function getLayoutList(data) {
  // 获取 布局列表
  return router({
    url: baseUrl+"layout/v1/getList",
    data: data,
    method: "POST",
  });
}

function bindTag(data) {
  // 获取 布局列表
  return router({
    url: baseUrl+"bind/v1/bind",
    data: data,
    method: "POST",
  });
}
function bindList(data) {
  // 获取 绑定数据列表
  return router({
    url: baseUrl+"bind/v1/getList",
    data:data,
    method: "POST",
  });
}
function unBind(data) {
  // 单个价签解绑
  return router({
    url: baseUrl+"bind/v1/unBind",
    data: data,
    method: "POST",
  });
}

function batchTransmit(data) {
  // 批量推送
  return router({
    url: baseUrl+"bind/v1/batchTransmit",
    data: data,
    method: "POST",
  });
}
function batchUnBind(data) {
  // 批量推送
  return router({
    url: baseUrl+"bind/v1/batchUnBind",
    data: data,
    method: "POST",
  });
}

/**
 * 
 * 以下为批量绑定相关接口
 * 
 *  **/

// 获取所有价签尺寸
function getTagList(data) {
  return router({
    url: baseUrl+"bind/v1/getBatchBindParamsList",
    data: data,
    method: "POST",
  });
}

// 获取价签尺寸对应布局
function getLayoutData(data) {
  return router({
    url: baseUrl+"layout/v1/getList",
    data: data,
    method: "POST",
  });
}

// 获取场景列表
function getScenarioList(data) {
  return router({
    url: baseUrl+"scenario/v1/getScenarioSimplifyList",
    data:data,
    method: "POST",
  });
}

// 获取商品接口
function getGoods(data) {
  return router({
    url: baseUrl+"item/v1/getSimplifyList",
    data: data,
    method: "POST",
  });
}

// 批量绑定关联
function batchBind(data) {
  return router({
    url: baseUrl+"bind/v1/batchBind",
    data: data,
    method: "POST",
  });
}

 export { 
  getByEslCode,
  getLayoutList,
  bindTag,
  bindList,
  unBind, 
  batchTransmit,
  batchUnBind,
  getTagList,
  getLayoutData,
  getScenarioList,
  getGoods,
  batchBind,
}