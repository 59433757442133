<template>
  <div class="associate-wrap">
    <div class="public-breadcrumb">
      {{ $t("assocaite.Welcome") }} >
      <span style="color: #3461ff">{{ $t("assocaite.Associate") }}</span>
    </div>
    <div class="top">
      <el-button
        class="left"
        type="primary"
        size="small"
        @click="multibatchTransmit"
        ><i class="el-icon-check" /><span>{{
          $t("assocaite.BatchTransmit")
        }}</span></el-button
      >
      <el-button class="right" @click="multiUnBind"
        ><img src="@/assets/img/unlink-icon.png" /><span>{{
          $t("assocaite.BatchUnlink")
        }}</span></el-button
      >
      <el-button class="right" @click="multiBind"
        ><img src="@/assets/img/link-icon.png" /><span>{{
          $t("assocaite.BatchLink")
        }}</span></el-button
      >
    </div>
    <div class="center-box" style="background: #fff">
      <div class="center">
        <div class="title">
          {{ $t("associationLog.Records") }}: {{ tableInfo.total }}
        </div>

        <el-divider />
        <div class="cont">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item :label="$t('assocaite.ESL')" prop="tagIdentity">
              <el-input
                v-model="form.tagIdentity"
                @input="getEslDetail"
                size="small"
                clearable
              />
            </el-form-item>
            <el-form-item
              :label="$t('assocaite.LayoutStrategy')"
              prop="strategyType"
            >
              <el-select
                v-model="form.strategyType"
                @change="handleChangeType"
                size="small"
              >
                <el-option
                  v-for="item in strategyTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
                <!-- <el-option :label="$t('assocaite.Normal')" :value='0'/>
                <el-option :label="$t('assocaite.SplitScreen')" :value='1'/>
                <el-option :label="$t('assocaite.SelfAdaption')" :value='2'/> -->
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('assocaite.Layout')" prop="layoutId">
              <el-select v-model="form.layoutStrategyId" size="small">
                <el-option
                  v-for="item in layoutList"
                  :key="item.layoutStrategyId"
                  :label="item.layoutStrategyName"
                  :value="item.layoutStrategyId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="item-box" v-for="(item, index) in itemList" :key="index">
            <!-- <span class="item-span">Item {{index + 1}}</span> -->
            <!-- <span class="item-span">{{$t('assocaite.Item')}} {{index + 1}}</span> -->
            <span class="item-span"
              >{{ $t("assocaite.Item") }} {{ item.id }}</span
            >
            <div class="item-browse">
              <el-input
                class="inputwidth"
                v-model="item.value"
                @input="enterCont"
                clearable
                size="small"
              />
              <span class="item-browse-span" @click="selectCommodity(index)"
                >{{ $t("itemsScenarios.EditDialog.Browse") }}...</span
              >
            </div>
          </div>
          <div class="link-btn">
            <el-button
              type="primary"
              size="small"
              @click="bind"
              :loading="btnDisabled"
              :disabled="btnDisabled"
              ><img src="@/assets/img/link-icon.png" /><span class="text">{{
                $t("assocaite.Link")
              }}</span></el-button
            >
          </div>
        </div>
      </div>
      <div class="bottom">
        <el-table
          ref="multipleTable"
          style="width: 100%"
          :data="tableList"
          row-key="goodsTagMiddleId"
          @selection-change="handleSelectionChange"
          show-empty
          :empty-text="$t('assocaite.NoData')"
          @sort-change="handleSort"
        >
          <el-table-column width="100" type="selection">
            <el-table-column width="55" type="selection" style="display: none">
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('assocaite.ESLCode')"
            sortable="custom"
            width="200"
            prop="tagIdentity"
          >
            <el-table-column prop="tagIdentity">
              <template slot="header" slot-scope="scope">
                <el-input
                  size="mini"
                  style="width: 120px"
                  v-model="tableInfo.tagIdentity"
                  @input="getTableList"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('assocaite.LayoutName')"
            sortable="custom"
            width="200"
            prop="layoutName"
          >
            <el-table-column prop="layoutName">
              <template slot="header" slot-scope="scope">
                <el-input
                  size="mini"
                  style="width: 120px"
                  v-model="tableInfo.layoutName"
                  @input="getTableList"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="$t('assocaite.ItemTag')"
            sortable="custom"
            width="200"
            prop="itemTag"
          >
            <el-table-column prop="itemTag">
              <template slot="header" slot-scope="scope"> </template>
            </el-table-column>
          </el-table-column>

          <el-table-column :label="$t('assocaite.Overview')" width="200">
            <el-table-column prop="imgUrl">
              <template slot-scope="scope">
                <a v-if="scope.row.imgUrl" @click="imageLg(scope.row.imgUrl)">
                  <el-image
                    :src="scope.row.imgUrl"
                    style="width: 180px; height: 60px"
                  />
                </a>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('assocaite.ItemCode')"
            sortable="custom"
            width="200"
            prop="itemCode"
          >
            <el-table-column prop="itemCode">
              <template slot="header" slot-scope="scope">
                <el-input
                  size="mini"
                  style="width: 120px"
                  v-model="tableInfo.itemCode"
                  @input="getTableList"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('assocaite.ItemName')"
            sortable="custom"
            width="200"
            prop="itemName"
          >
            <el-table-column prop="itemName">
              <template slot="header" slot-scope="scope">
                <el-input
                  size="mini"
                  style="width: 120px"
                  v-model="tableInfo.itemName"
                  @input="getTableList"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            fixed="right"
            :label="$t('assocaite.Action')"
            width="130"
          >
            <el-table-column prop="action">
              <template slot-scope="scope">
                <img
                  class="action-img"
                  @click="itemUnbind(scope)"
                  src="@/assets/img/action-icon.png"
                  alt=""
                />
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>

        <!-- <div class="d-flex justify-content-between flex-wrap b_pag">
          <div class="d-flex align-items-center">
            <span class="text-nowrap">
              Display
            </span>
            <el-select v-model="tableInfo.size" @change="getTableList">
              <el-option
                v-for="item in [10, 20 , 50, 100,200,500, 1000]"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <span class="text-nowrap">Strip</span>
          </div>
          <el-pagination
            background
            @size-change="getTableList"
            @current-change="getTableList"
            :page-size.sync="tableInfo.size"
            :current-page.sync="tableInfo.current"
            prev-text="Previous"
            next-text="Next"
            layout="prev, pager, next"
            :total="tableInfo.total"
          >
          </el-pagination>
        </div> -->
        <pagination :tableInfo="tableInfo" @changePage="changePage" />
      </div>
    </div>
    <el-dialog :visible.sync="dialogImageVisible" :title="$t('layout.Preview')">
      <div style="text-align: center">
        <el-image :src="checkedSvgImgUrl" />
      </div>
    </el-dialog>
    <!-- <单个解绑> -->
    <confirm-dialog
      :dialogVisible="dialogVisible"
      @handleClose="handleClose"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>
    <!-- < 批量重推 > -->
    <confirm-dialog
      :dialogVisible="dialogTransmitVisible"
      @handleClose="handleCloseTransmit"
      :confirmObj="confirmTransmitObj"
    >
    </confirm-dialog>
    <!-- < 批量解绑 > -->
    <confirm-dialog
      :dialogVisible="dialogUnbindVisible"
      @handleClose="handleCloseUnbind"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>

    <!-- 选择商品 -->
    <selectCommodity
      ref="selectCommodity"
      :scenarioId="form.scenarioId"
      @onSelectCommodity="handleSelectCommodity"
    ></selectCommodity>

    <!-- 批量绑定弹窗1 -->
    <stepOneDialog
      ref="stepOneDialogRef"
      @stepTwo="handleGoStepTwo"
    />

    <!-- 批量绑定弹窗2 -->
    <stepTwoDialog
      ref="stepTwoDialogRef"
      @stepOne="handleGoStepOne"
    />
  </div>
</template>
<script>
import { debounce } from "lodash";
import {
  getByEslCode,
  getLayoutList,
  bindTag,
  bindList,
  unBind,
  batchTransmit,
  batchUnBind,
} from "@/libs/api/associate";
import ConfirmDialog from "@/components/confirm-dialog";
import { commonStore } from "@/store/global-select-store";
import pagination from "@/components/pagination";
import selectCommodity from "@/components/select-commodity/index";

import stepOneDialog from './components/stepOneDialog.vue'
import stepTwoDialog from './components/stepTwoDialog.vue'

export default {
  components: {
    ConfirmDialog,
    pagination,
    selectCommodity,
    stepOneDialog,
    stepTwoDialog,
  },
  data() {
    return {
      commonStore,
      dialogImageVisible: false, // t图片预览
      checkedSvgImgUrl: "",
      // 0000000012    0000047464  item_code_01
      tagIdentity: "", // 输入的 ESl内容
      form: {
        tagIdentity: "",
        tagId: "", // 价签id (通过详情接口获取), 在绑定的时候需要向后端传入的数据
        strategyType: 0, // 布局类型（固定写死，进行选择）
        layoutStrategyId: "", // 选中的布局策略id
        layoutId: "", // 选中的布局策略同级的当前的children下面的layoutId，在绑定的时候需要向后端传入的数据
        layoutName: "", //
        scenarioId: "", // 场景id (选中的layout，当前的数据的场景id字段scenarioId)
      },
      strategyTypeList: [
        { id: 0, value: this.$t("assocaite.Normal") },
        { id: 1, value: this.$t("assocaite.SplitScreen") },
        { id: 2, value: this.$t("assocaite.SelfAdaption") },
      ],
      layoutList: [], // 布局列表
      groupSize: "", // 详情接口获取的布局尺寸
      itemList: [], // item列表
      itemId: [], // 向后端传的itemId合集
      maxBindNumberVal: 0,
      maxBindChildren: [], //最大的maxbind的当前children
      selectCommodityIndex: 0, // 选中的商品的index
      tableList: [],
      tableInfo: {
        tagIdentity: "",
        layoutName: "",
        itemTag: "",
        imgUrl: "",
        itemCode: "",
        itemName: "",
        current: 1,
        size: 10,
        total: 0,
        pages: 1,
        orders: [],
      },
      multipleSelection: [], // 多选选中的对象集合
      rules: {
        tagIdentity: [
          {
            required: true,
            message: this.$t("assocaite.ESLRequireTips"),
            trigger: "blur",
          },
        ],
        strategyType: [
          {
            required: true,
            message: this.$t("assocaite.StrategyTypeRequireTips"),
            trigger: "blur",
          },
        ],
        layoutId: [
          {
            required: true,
            message: this.$t("assocaite.LayoutRequireTips"),
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false, // 单个解绑
      dialogTransmitVisible: false, // 批量重推
      dialogUnbindVisible: false, // 批量解绑
      confirmObj: {
        // 单个解绑  批量解绑
        type: this.$t("ConfirmDialog.Warning"),
        cancelText: this.$t("ConfirmDialog.ThinkAgain"),
        confirmText: this.$t("ConfirmDialog.Yes"),
        content: this.$t("ConfirmDialog.UnBindTips"),
      },
      confirmTransmitObj: {
        // 批量重推
        type: this.$t("ConfirmDialog.Warning"),
        cancelText: this.$t("ConfirmDialog.ThinkAgain"),
        confirmText: this.$t("ConfirmDialog.Yes"),
        content: this.$t("ConfirmDialog.TransMitTips"),
      },
      unbandRowVal: null, // 单个解绑当行的数据
      btnDisabled: false,
    };
  },
  mounted() {
    commonStore.setInjectCallBack(this.getTableList);
    this.getTableList();
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
  },
  watch: {
    language(val) {
      if (val) this.getTableList();
    },
    "form.layoutStrategyId": {
      handler(val) {
        console.log("layoutStrategyId", this.form.layoutStrategyId);
        this.layoutList.forEach((t) => {
          if (t.layoutStrategyId === val) {
            this.form.scenarioId = t.scenarioId;
            this.itemList = [];
            // if (this.form.strategyType ===  0 || this.form.strategyType === 1) {
            //   console.log('maxBindNumber============',t.children[0])
            //   this.maxBindNumberVal = +t.children[0].maxBind
            //   this.form.layoutId = t.children[0].layoutId
            //   this.form.layoutName = t.children[0].layoutName
            //   for(var i = 0; i < this.maxBindNumberVal; i++) { // (之前方案直接取index)
            //     this.itemList.push({id: i + 1, value: ''})
            //   }
            // }

            // 取 children 数组下面maxBindNumber值未最大时的当前对象的数据
            if (t.children.length > 1) {
              // const maxBindNumberList = []
              // t.children.forEach(i => {
              //   maxBindNumberList.push(i.maxBindNumber)
              // })
              // this.maxBindNumberVal = Math.max(...maxBindNumberList)
              const maxResult = t.children.sort((a, b) => {
                return b.maxBind - a.maxBind;
              })[0];
              this.form.layoutId = maxResult.layoutId;
              this.form.layoutName = maxResult.layoutName;
              this.maxBindNumberVal = maxResult.maxBind;
              this.maxBindChildren = maxResult.children;
            } else {
              this.maxBindNumberVal = +t.children[0].maxBind;
              this.form.layoutId = t.children[0].layoutId;
              this.form.layoutName = t.children[0].layoutName;
              this.maxBindChildren = t.children[0].children;
            }
            this.maxBindChildren.forEach((t) => {
              //  (取maxbind最大值时的当前children下面的 itemTag 不为空的值)
              if (t.itemTag) {
                this.itemList.push({ id: t.itemTag, value: "" });
              }
            });

            console.log("this.maxBindChildren=======", this.maxBindChildren);
          }
        });
        console.log("this.itemList", this.maxBindNumberVal, this.itemList);
        console.log("2222222amx-------", this.itemList);
      },
    },
  },
  methods: {
    // 选择商品
    selectCommodity(val) {
      this.selectCommodityIndex = val;
      this.$refs.selectCommodity.open();
    },
    // 选择商品确定
    handleSelectCommodity(arr) {
      console.log("页面处理商品选择", arr);
      if (arr.length > 0) {
        this.itemList[this.selectCommodityIndex]["value"] = arr[0].itemCode;
      }
    },
    imageLg(url) {
      // window.open(url, "_blank");
      this.checkedSvgImgUrl = url;
      this.dialogImageVisible = true;
    },
    // 翻页
    changePage() {
      this.getTableList();
    },
    // 修改布局类型
    handleChangeType() {
      console.log("strategy====", this.form.strategyType);
      this.form.layoutStrategyId = "";
      if (this.form.tagIdentity === "") return;
      this.getLayout(this.groupSize);
      this.itemList = [];
    },
    // 获取价签详情
    getEslDetail: debounce(async function () {
      try {
        // if (this.form.tagIdentity === '') return
        this.form.tagId = "";
        this.form.strategyType = 0;
        this.form.layoutStrategyId = "";
        this.groupSize = "";
        this.itemList = [];
        const params = {
          tagIdentity: this.form.tagIdentity,
          storeId: localStorage.getItem("storeId"),
        };
        const res = await getByEslCode(params);
        console.log("1111111res=========", res);
        if (res.status === 200 && res?.data.code === 1) {
          // tagTypeId  === groupSize
          const data = res.data?.data;
          this.groupSize = data?.tagTypeId;
          this.form.tagId = data?.tagId;
          this.getLayout();
          this.getTableList();
        } else {
          this.form.tagId = "";
          this.form.strategyType = 0;
          this.form.layoutStrategyId = "";
          this.groupSize = "";
          this.itemList = [];
          this.$message({
            message: res.data.message,
            type: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000),
    // 获取布局列表
    async getLayout() {
      try {
        const params = {
          storeId: localStorage.getItem("storeId"),
          groupSize: this.groupSize,
          strategyType: this.form.strategyType,
          current: 0,
          size: 0,
        };
        const res = await getLayoutList(params);
        if (res.status === 200 && res?.data?.code === 1) {
          this.layoutList = res?.data?.data[0]?.children;
        } else {
          this.$message({
            message: res.data.message,
            type: "warning",
          });
        }
        console.log("this.layoutList======", this.layoutList);
      } catch {
        console.log(error);
      }
    },
    enterCont() {
      console.log("输入内容==============", this.itemList);
      this.itemId = this.itemList.map((item, index) => {
        return item.id + ":" + item.value;
      });
      // this.itemId = ['1,123']
    },
    // 绑定价签
    bind() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnDisabled = true;
          this.itemId =
            this.itemList &&
            this.itemList.map((item, index) => {
              return item.id + ":" + item.value;
            });
          const params = {
            tagId: this.form.tagId,
            itemId: this.itemId,
            layoutId: this.form.layoutId,
            scenarioId: this.form.scenarioId,
            storeId: localStorage.getItem("storeId"),
          };
          console.log("params=====", params);
          bindTag(params).then((res) => {
            console.log("message====res=======", res);
            if (res.status === 200 && res.data?.code === 1) {
              this.btnDisabled = false;
              this.getTableList();
              this.$message.success(this.$t("assocaite.LinkSuccess"));
            } else {
              this.btnDisabled = false;
              this.$message({
                message: res.data.message,
                type: "warning",
              });
            }
          });
        }
      });
    },
    handleSort(column, prop, order) {
      console.log("表格排序", column, prop, order);
      let arr = [];
      if (column.order) {
        let str1 = column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ":" + column.prop;
        arr.push(str1);
      } else {
        arr = [];
      }

      this.tableInfo.orders = arr;
      this.getTableList();
    },
    // 获取table表格
    getTableList: debounce(function () {
      const params = {
        storeId: localStorage.getItem("storeId"),
        // tagIdentity:this.form.tagIdentity,
        tagIdentity: this.tableInfo.tagIdentity,
        layoutName: this.tableInfo.layoutName,
        itemName: this.tableInfo.itemName,
        itemCode: this.tableInfo.itemCode,
        itemTag: this.form.itemTag,
        current: this.tableInfo.current,
        size: this.tableInfo.size,
        // ['layoutName:desc','itemName: desc','itemCode: desc']
        orders: this.tableInfo.orders,
      };
      bindList(params).then((res) => {
        if (res.status === 200 && res?.data?.code === 1) {
          const data = res?.data?.data;
          this.tableList = data?.records;
          this.tableInfo.total = data?.total;
        } else {
          this.$message({
            message: res.data.message,
            type: "warning",
          });
        }
        console.log("获取表格===============", this.tableList);
      });
    }, 1000),
    // 关闭提示弹框按钮
    async handleClose(val) {
      console.log("3333333333handleClose", val);
      this.dialogVisible = false;
      if (val === "confirm") {
        const res = await unBind({
          goodsTagMiddleId: this.unbandRowVal?.row?.goodsTagMiddleId,
        });
        if (res.status === 200 && res.data?.code === 1) {
          this.getTableList();
          this.$message.success(this.$t("assocaite.BatchUnlinkSuccess"));
        } else {
          this.$message({
            message: res.data.message,
            type: "warning",
          });
        }
      }
    },
    // 单个解绑
    itemUnbind(val) {
      console.log("itemUnbind===========", val);
      this.dialogVisible = true;
      this.unbandRowVal = val;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("multipleSelection", this.multipleSelection);
    },
    // 批量弹框
    multibatchTransmit() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t("assocaite.TransmitRequireTips"));
        return;
      }
      this.dialogTransmitVisible = true;
    },
    // 批量推送
    handleCloseTransmit(val) {
      this.dialogTransmitVisible = false;
      if (val === "confirm") {
        const arr = [];
        this.multipleSelection.forEach((item) => {
          arr.push(item.tagId);
        });
        batchTransmit({ tagIdList: arr || [] }).then((res) => {
          if (res.status === 200 && res.data?.code === 1) {
            this.getTableList();
            this.$message.success(this.$t("assocaite.BatchTransmitSuccess"));
          } else {
            this.$message({
              message: res.data.message,
              type: "warning",
            });
          }
        });
      }
    },
    // 批量解绑弹框
    multiUnBind() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t("assocaite.TransmitRequireTips"));
        return;
      }
      this.dialogUnbindVisible = true;
    },
    // 批量绑定弹框multiBind第一步
    multiBind() {
      this.$refs.stepOneDialogRef.openDialog();
    },
    // 批量绑定弹框multiBind第二步
    handleGoStepTwo(row) {
      this.handleGoTwoDialog(row)
    },
    handleGoStepOne(row) {
      this.$refs.stepOneDialogRef.openDialog(row);
    },
    // 批量绑定弹框multiBind第二步方法
    handleGoTwoDialog(row) {
      this.$refs.stepTwoDialogRef.openDialog(row)
    },
    // 批量解绑
    handleCloseUnbind(val) {
      //goodsTagMiddleId
      this.dialogUnbindVisible = false;
      if (val === "confirm") {
        const arr = [];
        this.multipleSelection.forEach((item) => {
          arr.push(item.goodsTagMiddleId);
        });
        console.log("批量==============", arr);
        batchUnBind({ goodsTagMiddleIdList: arr || [] }).then((res) => {
          if (res.status === 200 && res.data?.code === 1) {
            this.getTableList();
            this.$message.success(this.$t("assocaite.BatchUnlinkSuccess"));
          } else {
            this.$message({
              message: res.data.message,
              type: "warning",
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.associate-wrap {
  .top {
    width: 100%;
    height: 70px;
    background: #fff;
    margin: 20px 0 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-radius: 6px;
    ::v-deep .el-button {
      color: #fff;
      border-radius: 6px;
      padding: 9px 15px;
    }
    .left {
      // margin-right: 20px;
      background: #3461ff;
      position: relative;
      img {
        position: absolute;
        left: 23px;
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 25px;
      }
      ::v-deep .el-icon-check {
        font-size: 20px;
        position: absolute;
        left: 10px;
        top: 5px;
      }
    }
    .right {
      background: #fc184d;
      position: relative;
      span {
        margin-left: 25px;
      }
      img {
        position: absolute;
        left: 16px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .center-box {
    border-radius: 12px;
    .center {
      background: #fff;
      border-radius: 6px;
      .title {
        height: 40px;
        line-height: 40px;
        padding-left: 15px;
      }
      .el-divider--horizontal {
        margin: 0 0px 20px 0;
      }
      .cont {
        .item-box {
          display: flex;
          justify-content: center;
          position: relative;
          .item-span {
            width: 140px;
            text-align: right;
            padding-right: 10px;
            line-height: 32px;
            height: 32px;
            display: inline-block;
            margin-bottom: 10px;
          }
          .item-browse {
            position: relative;
            width: 300px;
            .item-browse-span {
              background: #3461ff;
              color: #fff;
              position: absolute;
              right: 0;
              top: 0;
              // bottom: 0;
              border-radius: 3px;
              padding: 0px 20px;
              cursor: pointer;
              bottom: 3px;
              height: 32px;
              line-height: 32px;
            }
          }
        }
        .link-btn {
          display: flex;
          justify-content: center;
          margin-left: 100px;
          .el-button {
            border-radius: 6px;
            background: #28c76f !important;
            color: #fff;
            width: 120px;
            border-color: #28c76f !important;
          }
          ::v-deep .el-button {
            position: relative;
            border-radius: 6px;
          }
          span {
            margin-left: 20px;
            font-size: 16px;
            img {
              position: absolute;
              left: 20px;
              top: 10px;
              width: 16px;
              height: 16px;
            }
          }
        }
        .tip {
          color: red;
          font-size: 12px;
          margin-bottom: 0px;
          margin-top: -20px;
          margin-left: -250px;
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        justify-content: center;
      }
      ::v-deep .el-form-item__label {
        width: 140px;
        text-align: right;
      }
      ::v-deep .el-input {
        width: 300px;
      }
      ::v-deep .el-input__inner {
        // height: 50px;
      }
    }
  }
  .bottom {
    margin-top: 20px;
    .action-img {
      display: inline-block;
      width: 14px;
      font-size: 18px;
      cursor: pointer;
    }
    .overview-img {
      width: 100px;
      height: 50px;
      display: inline-block;
    }
    ::v-deep .el-image__inner {
      object-fit: contain;
    }
    ::v-deep .el-table__cell {
      text-align: center !important;
    }
    ::v-deep .is-group tr:nth-child(2) .el-checkbox {
      display: none;
    }
  }
}
::v-deep .el-table--border,
.el-table--group {
  border: none !important;
}
.inputwidth {
  width: 70% !important;
}
</style>