<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :before-close="cancel"
    :close-on-click-modal="false"
    append-to-body
    width="820px"
    class="task-manger-step-one-dialog"
    style="padding: 10px"
  >
    <div slot="title" class="dialog-header">
      <div class="dialog-index">2</div>
      <span class="dialog-title"> {{ $t("assocaite.DiologTwo.title") }} </span>
    </div>
    <div v-if="dialogVisible">
      <el-form
        ref="form"
        :model="form"
        label-width="130px"
        label-position="right"
        size="small"
      >
        <el-form-item
          :label="$t('assocaite.DiologTwo.type')"
          prop="executeType"
        >
          <el-radio v-model="form.executeType" label="0">{{
            $t("assocaite.DiologTwo.type1")
          }}</el-radio>
          <el-radio
            v-model="form.executeType"
            :disabled="initData.bindType == 1"
            label="1"
            >{{ $t("assocaite.DiologTwo.type2") }}</el-radio
          >
        </el-form-item>
        <el-form-item
          :label="$t('assocaite.DiologTwo.goodsType')"
          prop="relationship"
        >
          <!--  全部 -->
          <el-radio v-model="form.isBind" label="0">{{
            $t("assocaite.DiologTwo.goodsType1")
          }}</el-radio>
          <!-- 未绑定 -->
          <el-radio v-model="form.isBind" label="1">{{
            $t("assocaite.DiologTwo.goodsType2")
          }}</el-radio>
        </el-form-item>
        <el-form-item :label="$t('assocaite.DiologTwo.isCommit')">
          <el-switch v-model="form.isSendTask" />
        </el-form-item>
      </el-form>
      <div class="table-content">
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ 'text-align': 'center' }"
          row-key="id"
          @sort-change="handleSort"
          @selection-change="handleSelectionChange"
          @row-click="singleElection"
          class="bindGoodsDialog"
        >
          <el-table-column
            v-if="initData.bindType == 0 && form.executeType != 1"
            align="center"
            type="selection"
          >
            <el-table-column
              align="center"
              width="55"
              type="selection"
              style="display: none"
            >
            <template slot="header"> </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            v-if="initData.bindType == 1"
            align="center"
            width="55"
          >
            <el-table-column  width="30" align="center">
              <template slot="header"> </template>
              <template slot-scope="scope">
              <el-radio
                class="radio"
                v-model="templateSelection"
                :label="scope.row.itemId"
                >&nbsp;</el-radio
              >
            </template>
            </el-table-column>
            
          </el-table-column>
          <!-- 门店名称 -->
          <el-table-column
            :label="$t('assocaite.DiologTwo.table.Store Name')"
            prop="storeName"
          >
            <el-table-column prop="storeName" min-width="150" align="center">
              <template slot="header">
                <el-input
                  v-model="goodsParams.storeName"
                  size="mini"
                  placeholder=""
                  @input="getGoodsList"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
          <!-- 商品名称 -->
          <el-table-column
            :label="$t('assocaite.DiologTwo.table.Goods Name')"
            prop="itemName"
            sortable="custom"
          >
            <el-table-column prop="itemName" min-width="150" align="center">
              <template slot="header">
                <el-input
                  v-model="goodsParams.itemName"
                  size="mini"
                  placeholder=""
                  @input="getGoodsList"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
          <!-- 商品条码 -->
          <el-table-column
            :label="$t('assocaite.DiologTwo.table.Goods Code')"
            prop="itemCode"
            sortable="custom"
          >
            <el-table-column prop="itemCode" min-width="150" align="center">
              <template slot="header">
                <el-input
                  v-model="goodsParams.itemCode"
                  size="mini"
                  placeholder=""
                  @input="getGoodsList"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <pagination :tableInfo="goodsParams" @changePage="getGoodsList" />

      <div style="height: 50px"></div>

      <div slot="footer" class="dialog-footer__1">
        <el-button
          class="task-manger-button-next gray-button"
          @click="handlePrior"
          size="small"
        >
          {{ $t("assocaite.DiologTwo.Prior") }}
        </el-button>
        <el-button
          class="task-manger-button-next green-button"
          @click="submitForm"
          size="small"
        >
          {{ $t("assocaite.DiologTwo.Next") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import pagination from "@/components/pagination";

import { getGoods, batchBind } from "@/libs/api/associate";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      dialogVisible: false,
      templateSelection: "",
      initData: {
        scenarioId: "",
      },
      scenarioList: [],
      form: {
        executeType: "0",
        isBind: "0",
        isSendTask: false,
      },
      goodsParams: {
        itemCode: "",
        itemName: "",
        storeId: localStorage.getItem("storeId"),
        storeName: "",
        current: 1,
        size: 10,
        orders: [],
        total: 0,
      },
      tableData: [],
      itemId: []
    };
  },
  computed: {
    operateDiabled() {
      if (this.form && this.form.taskScheduleId) {
        return true;
      }
      return false;
    },
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getGoodsList();
      } else {
        this.templateSelection = ""
        this.scenarioList = []
        this.form = {
          executeType: "0",
          isBind: "0",
          isSendTask: false,
        }
        this.goodsParams = {
          itemCode: "",
          itemName: "",
          storeId: localStorage.getItem("storeId"),
          storeName: "",
          current: 1,
          size: 10,
          orders: [],
          total: 0,
        }
        this.tableData = []
        this.itemId = []
      }
    },
    form: {
      deep:true,//true为进行深度监听,false为不进行深度监听
      handler(){
        this.getGoodsList()
      }
    }
  },
  methods: {
    openDialog(row) {
      this.initData = row;
      this.dialogVisible = true;
      console.log("=========");
      console.log(this.initData);
    },
    cancel() {
      this.dialogVisible = false;
    },
    // 上一步方法
    handlePrior() {
      this.$emit("stepOne", this.initData);
      this.dialogVisible = false;
    },

    // 多选
    handleSelectionChange(selection) {
      console.log(selection);
      this.itemId = []
      this.itemId = selection.map(item => {
        return item.itemCode
      })
      console.log(this.itemId)
    },

    singleElection(row) {
      this.templateSelection = row.itemCode;
      // this.checkList = this.tableData.filter((item) => item.id === row.id);
      this.itemId = []
      this.itemId.push(this.templateSelection)
    },

    // handleSort排序
    handleSort(column) {
      console.log('排序',column)
        let arr = []
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        this.goodsParams.orders = arr;
        this.getGoodsList()
    },

    //获取商品列表
    getGoodsList() {
      let params = {
        ...this.goodsParams,
        scenarioId: this.initData.scenarioId, //场景id
        bindState: this.form.isBind == 1 ? 0 : "", //绑定状态(1：绑定 0:未绑定)	是	Integer	query
      };
      getGoods(params).then((res) => {
        this.goodsParams.total = res.data.data.total
        this.tableData = res.data.data.records;
      });
    },

    submitForm() {
      console.log(this.initData)
      let userData = JSON.parse(localStorage.getItem("userData"));
      let tagTypeIdObj = {}
      this.initData.selectedData.map(item => {
        // tagTypeIdObj[item.tagTypeId] = item.layoutStrategyId
        tagTypeIdObj[item.tagTypeId] = item.layoutId
      })

      // console.log(this.itemId)
      const formatItemId = this.itemId.map(item => {
        return '1:'+item
      })
      // console.log(formatItemId)
      // return

      const params = {
        executeType: this.form.executeType,
        merchantId: userData.merchantId,
        storeId: localStorage.getItem("storeId"),
        isFilter: this.initData.isFilter ? 0 : 1,
        bindType: this.initData.bindType,
        itemId: formatItemId,
        isBind: this.form.isBind == 1 ? 1 : 0,
        isSendTask: this.form.isSendTask?0:1,
        scenarioId: this.initData.scenarioId,
        searchParams: {},
        tagTypeId: tagTypeIdObj
      }

      if(params.executeType == 1){
        params.searchParams = {
          ...this.goodsParams,
          scenarioId: this.initData.scenarioId, //场景id
          bindState: this.form.goodsType, 
        }
      } else {
        params.searchParams = {}
        if(this.itemId.length < 1){ 
          this.$message.error(this.$t('assocaite.DiologTwo.goods null'))
          return
        }
      }

      batchBind(params).then(res => {
        console.log(res)
        if(res.data.code == 1){
          this.$message.success(this.$t('assocaite.DiologTwo.do success'))
          this.dialogVisible = false
        }
      })
      return
        this.$emit("stepTwo", this.form);
        this.dialogVisible = false;
    },
  },
};
</script>


<style>
.bindGoodsDialog thead.is-group tr:nth-child(2) th.el-table__cell .el-checkbox{
  display: none;
}
</style>
<style lang="scss" scoped>
.task-manger-step-one-dialog {
  .el-radio-group {
    vertical-align: text-top;
  }
  .dialog-header {
    display: flex;
    align-items: center;
  }
  .dialog-index {
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    background: linear-gradient(199deg, #29f19c 0%, #14cad5 100%);
    border-radius: 0px 20px 20px 20px;
    text-align: center;
    font-family: Impact;
    font-weight: 400;
    color: #ffffff;
  }

  .dialog-title {
    margin-left: 10px;
    font-size: 16px;
    font-family: Verdana;
    font-weight: 400;
    color: #35b871;
  }

  .el-dialog__header {
    background-color: #e9f9ef;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: #d3f4e1;
  }

  .el-dialog__body {
    padding: 30px 20px !important;
  }

  // .el-radio-button__inner, .el-radio-group {
  //   display: inline-block;
  //   vertical-align: middle;
  //   font-size: 0;
  // }

  //  .el-radio-group {
  //   display: inline-block;
  //   vertical-align: middle;
  //   font-size: 0;
  // }

  .dialog-footer__1 {
    display: flex;
    justify-content: center;
    margin-top: 50;
  }

  .task-manger-button-next {
    // border: 0px;
    min-width: 110px;
  }
}
</style>
